
































import { Component, Prop, Vue } from "vue-property-decorator";
import PrecifiqueService from "@/services/precifiqueService";

@Component({})
export class LoginPrecifiqueForm extends Vue {


  public emailButton: boolean = false;
  public email: string = '';
  public isValidEmail: boolean = true;
  public isFormatedEmail: boolean = true ;
  public buttonEnter: boolean = false;
  public nextPage: boolean = false;


 


 public async validateEmail( email: string ) {

  if (!this.isValidEmail) {
    this.isValidEmail = true
  }
  
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    this.isFormatedEmail = emailRegex.test(this.email);
   
      if(!this.isFormatedEmail) {
     
      this.buttonEnter = false;
      }
      else {
        
        this.buttonEnter = true;
      }
    
  }


  public async submitForm() {

    if (this.isFormatedEmail) {

      localStorage.setItem('email', this.email);

      const precifiqueService = new PrecifiqueService();
      this.buttonEnter = false;

      try {
        await precifiqueService.sendEmail({
          email: this.email,

        })
        this.buttonEnter = false;
        this.nextPage = true;
        this.$emit("toStep", "2")
        
        this.$alert('Email enviado com sucesso', '', 'success',
          {
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });

      } catch (e) {
        console.error(e);
        this.$alert('Erro ao enviar o email', '', 'error', {
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        })
        this.isValidEmail = false;
        
      }
    }
    this.buttonEnter = true;
  }
}

export { LoginPrecifiqueForm as default };


