import mixpanel from "mixpanel-browser";


mixpanel.init("5fa4a9b0819757c37da6e5276ebcf241", {
    loaded: function () {
       
    }
});



export default mixpanel;