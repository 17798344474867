































import { Component, Prop, Vue } from "vue-property-decorator";
import PrecifiqueService from "@/services/precifiqueService";

@Component({})
export class CodePrecifiqueForm extends Vue {

  public emailButton: boolean = false;
  public email: string | null  = '';
  public token: string  = '';
  public key: string = '';
  public isValidCode: boolean = true;
  public buttonEnter: boolean = false;

  public nextPage: boolean = false;







  validateCode() {

    if (!this.isValidCode) {
    this.isValidCode = true
  }
    const codeRegex = /^\d{4}$/;
    this.isValidCode = codeRegex.test(this.token);
    if (this.isValidCode) {
      this.buttonEnter = true;
    }
    else {
      this.buttonEnter = false;
    }
    
  }

  public async submitForm() {
  
    if (this.isValidCode) {

      this.email = localStorage.getItem('email'); 
      const precifiqueService = new PrecifiqueService();
      this.buttonEnter = false;
      try {
        const response =  await precifiqueService.sendToken({
        email: this.email,
        token: this.token
    })

    this.key = response.data.token
    this.buttonEnter = false;
    this.nextPage = true;
    this.$emit("toStep", "3")

    localStorage.setItem('key', this.key);
     
        this.$alert('Código enviado com sucesso', '', 'success',
          {
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          })
          ;
      } catch (e) {
        console.error(e);
        this.$alert('Código incorreto', '', 'error', {
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        
      }
    }
    this.buttonEnter = true;
  }
}


export { CodePrecifiqueForm as default };


