








































import { Component, Prop, Vue } from "vue-property-decorator";
import PrecifiqueService from "@/services/precifiqueService";

@Component({})
export class PasswordPrecifiqueForm extends Vue {


  public emailButton: boolean = false;
  public email: string | null = '';
  public password: string = '';
  public passwordConfirm: string = '';
  public isValidPassword: boolean = true;
  public isValidConfirmPassword: boolean = true;
  public buttonEnter: boolean = false;
  public key: string | null= '';
  public nextPage: boolean = false;


 


  validatePassword() {

    if (!this.isValidPassword) {
    this.isValidPassword = true
  }
    const PasswordRegex = /^.{6,}$/;
    this.isValidPassword = PasswordRegex.test(this.password);
    if (this.isValidPassword) {
      this.buttonEnter = false;
    }
    else {
      this.buttonEnter = false;
    }
    
  }

  validatePasswordConfirm() {

    
    if ( this.passwordConfirm == this.password){
      this.isValidConfirmPassword = true;
      this.buttonEnter = true;
    }
    else {
      this.isValidConfirmPassword = false;
      this.buttonEnter = false;
    }
    
  }



  public async submitForm() {
    if (this.isValidPassword) {
         
    this.email = localStorage.getItem('email');
    this.key = localStorage.getItem('key');

      const precifiqueService = new PrecifiqueService();
      this.buttonEnter = false;
      
       try {
         await precifiqueService.sendPassword(this.key,{
          email: this.email,
          password: this.password
        });

        this.buttonEnter = false;
        this.$emit("toStep", "4")

        
        this.$alert('Senha alterada com sucesso', '', 'success',
          {
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          })
          this.nextPage = true;
       } catch (e) {
         console.error(e);
         this.$alert('Erro ao alterar senha', '', 'error', {
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
         timer: 3000,
         });
        
      }
    }
    this.buttonEnter = true;
  }
}


export { PasswordPrecifiqueForm as default };


