











































import { Component, Prop, Vue } from "vue-property-decorator";
import PrecifiqueService from "@/services/precifiqueService";

@Component({})
export class AccountDeletionRequestForm extends Vue {


  public emailButton: boolean = false;
  public email: string = '';
  public password: string = '';
  public isValidPass: boolean = true;
  public isValidEmail: boolean = true;
  public isFormatedEmail: boolean = true ;
  public buttonEnter: boolean = false;
  public nextPage: boolean = false;

  public async notEmpty() {
    if (this.password.trim().length > 0) {
      this.isValidPass = true
    } else {
      this.isValidPass = false
    }
  }

  public async validateEmail( email: string ) {

  if (!this.isValidEmail) {
    this.isValidEmail = true
  }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    this.isFormatedEmail = emailRegex.test(this.email);

      if(!this.isFormatedEmail) {

      this.buttonEnter = false;
      }
      else {

        this.buttonEnter = true;
      }

  }

  public async submitForm() {
    if (!this.isFormatedEmail) {
      this.showAlert('Formato de email incorreto', 'error');
      return;
    }

    this.buttonEnter = false;
    localStorage.setItem('email', this.email);
    const precifiqueService = new PrecifiqueService();

    try {
      await precifiqueService.login({
        email: this.email,
        password: this.password
      });

      const confirmationResult = await this.showConfirmationDialog();

      if (confirmationResult) {
        await this.deleteAccount(precifiqueService);
        await this.$router.push({name: 'accountDeleted'});
      } else {
        this.showAlert('Operação cancelada', 'info');
      }
    } catch (e) {
      console.error(e);
      this.showAlert('Senha e/ou email incorretos', 'error');
      this.isValidEmail = false;
      this.isValidPass = false;
    } finally {
      this.buttonEnter = true;
    }
  }

  async showConfirmationDialog() {
    return this.$confirm(
      'Ao excluir a sua conta todas as informações relacionadas a ' +
      'ela serão excluídas e não há como reverter essa operação. Tem certeza que deseja prosseguir?',
      'Ação irreversivel: tem certeza?', 'warning', {
        toast: false,
        position: 'center',
        showConfirmButton: true,
        confirmButtonText: 'Excluir conta',
        cancelButtonText: 'Deixar para depois',
        showCancelButton: true
      }
    ).catch(error => {
      console.error('Error in confirmation dialog:', error);
      return false;
    });
  }

  async deleteAccount(precifiqueService) {
    try {
      await precifiqueService.deleteAccount({
        email: this.email,
        password: this.password
      });
    } catch (error) {
      console.error('Error deleting account:', error);
      this.showAlert('Ocorreu um erro ao excluir a conta', 'error');
    }
  }

  showAlert(message, type) {
    this.$alert(message, '', type, {
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });
  }
}

export { AccountDeletionRequestForm as default };


